import React from 'react';
import { Stack, Typography } from '@mui/material';
import CampaignProgressBar from './CampaignProgressBar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../../theme';



function CampaignBox(props) {
    const navigate = useNavigate();
    const isReviewed = props.campaign.current_progress === props.campaign.max_progress;

    function handleClick () {
        navigate(`/physicianDashboard/headline/${props.campaign.campaign_id}`);
    }

    const boxShadowStyle = 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,rgba(0, 0, 0, 0.2) 0px 1px 5px 3px';

    if (props.campaign.campaign_type === 'compliance') {
        return (
            <Stack p={2} spacing={1} sx={{ borderRadius: '4px', backgroundColor: 'white', boxShadow: `${boxShadowStyle}`}} onClick={handleClick} className='pendo-compliance-campaign-summary'>
                <Stack direction='row' spacing={0.5} alignItems='center'>
                    { isReviewed && <CheckCircleIcon sx={{ color:`${theme.palette.green.taskText}`, fontSize: '1.25rem'}}  /> }
                    <Typography variant='heavy' sx={{ fontSize: '18px' }}>{props.campaign.campaign_name}</Typography>
                </Stack>
                <Typography variant='normal' sx={{ fontSize: '14px' }}>Contract Compliance Review - <NumericFormat value={props.campaign.total_capturable_opportunity / 100} prefix={'$'} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} /> opportunity</Typography>
                <Stack spacing={1}>
                    <Typography variant='normal' sx={{ fontSize: '12px' }}>
                        { isReviewed ? 
                            <strong>All swap opportunities reviewed</strong> :
                            <><strong>{props.campaign.current_progress}</strong> of <strong>{props.campaign.max_progress}</strong> swap opportunities reviewed</>
                        }
                    </Typography>
                    <CampaignProgressBar isReviewed={isReviewed} num_steps={props.campaign.max_progress} current_step={props.campaign.current_progress} />
                </Stack>
            </Stack>
        );
    }

    if (props.campaign.campaign_type === 'optimization') {
        return (
            <Stack p={2} spacing={1} sx={{ borderRadius: '4px', backgroundColor: 'white', boxShadow: `${boxShadowStyle}` }} onClick={handleClick} className='pendo-optimization-campaign-summary'>
                <Stack direction='row' spacing={0.5} alignItems='center'>
                    { isReviewed && <CheckCircleIcon sx={{ color:`${theme.palette.green.taskText}`, fontSize: '1.25rem' }} /> }
                    <Typography variant='heavy' sx={{ fontSize: '18px' }}>{props.campaign.hospital.name}</Typography>
                </Stack>
                <Stack direction='row' spacing={0.5} alignItems='center'>
                    <Typography variant='normal' sx={{ fontSize: '14px' }}>Preference Card Review</Typography> 
                    { props.campaign.total_capturable_opportunity > 100000 &&
                        <Typography variant='normal' sx={{ fontSize: '14px' }}> - <NumericFormat value={props.campaign.total_capturable_opportunity / 100} prefix={'$'} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} /> opportunity</Typography>
                    }
                </Stack>
                <Stack spacing={1}>
                    <Typography variant='normal' sx={{ fontSize: '12px' }}>
                        { props.campaign.total_capturable_opportunity > 100000 ? 
                            isReviewed ? 
                                <><strong>Top {props.campaign.max_progress} cards reviewed</strong> - {props.campaign.total_drafts - props.campaign.max_progress} additional cards available</> :
                                <><strong>{props.campaign.current_progress}</strong> of <strong>{props.campaign.max_progress}</strong> top cards reviewed</>
                            :
                            <>{props.campaign.total_drafts} cards available for review</>
                        }
                        
                    </Typography>
                    <CampaignProgressBar isReviewed={isReviewed} num_steps={props.campaign.max_progress} current_step={props.campaign.current_progress} />
                </Stack>
            </Stack>
        );
    }

    return <></>;
}

export default CampaignBox;
