import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useGetCampaignQuery } from '../../../api/campaignSlice';
import { useMemo } from 'react';


function TextingPreviewSummary(props) {
    const {currentData: campaignInfo} = useGetCampaignQuery({
        campaignId: props.campaignId, 
        include: 'drafts'
    });

    const isKickoffText = props.textType === 'kickoff';
    const isFollowUpText = props.textType === 'follow-up';

    const physicians = useMemo(() => {
        const physicians = {};
        campaignInfo?.drafts.forEach((draft) => {
            if (!physicians[draft.card.doctor.id]) {
                physicians[draft.card.doctor.id] = draft.card.doctor;
            }
        });
        return physicians;
    }, [campaignInfo]);

    const physiciansWithoutPhoneNumber = useMemo(() => {
        return Object.values(physicians).filter((physician) => !physician.phone_number);
    }, [physicians]);

    const physiciansWithTextingDisabled = useMemo(() => {
        return Object.values(physicians).filter((physician) => physician.do_not_text);
    }, [physicians]);

    const physiciansWithReviewsCompleted = useMemo(() => {
        const physiciansToReturn = [];
        campaignInfo?.drafts.forEach((draft) => {
            Object.values(draft.recommendations).forEach((recCategory) => {
                recCategory.forEach((recommendation) => {
                    if (recommendation.status !== 'open' && physiciansToReturn.indexOf(draft.card.doctor.id) === -1) {
                        physiciansToReturn.push(draft.card.doctor.id);
                    }
                });
            });
        });
        return physiciansToReturn;
    }, [physicians, campaignInfo]);

    const recentlyTextedPhysicians = useMemo(() => {
        return Object.values(physicians).filter((physician) => physician.was_recently_texted);
    }, [physicians]);

    const physiciansExpectedToReceiveTextMessage = useMemo(() => {
        if (isKickoffText) {
            return Object.values(physicians).filter((physician) => !physician.do_not_text && physician.phone_number && !physician.was_recently_texted);
        } else if (isFollowUpText) {
            return Object.values(physicians).filter((physician) => !physician.do_not_text && physician.phone_number && physiciansWithReviewsCompleted.indexOf(physician.id) === -1 && !physician.was_recently_texted);
        }
        return null;
    }, [physicians, isKickoffText, isFollowUpText, physiciansWithReviewsCompleted]);

    
    
    return (
        <Stack p={4} justifyContent='space-between' height='100%'>
            <Stack spacing={4}>
                <Stack>
                    <Typography variant='heavy' sx={{ 'fontSize': '20px' }}>Campaign Name: {campaignInfo?.name}</Typography>
                    <Typography variant='light' sx={{ 'fontSize': '14px' }}>Hospital: {campaignInfo?.hospital.name}</Typography>
                    <Typography variant='light' sx={{ 'fontSize': '14px' }}>Campaign ID: {campaignInfo?.id}</Typography>
                </Stack>
                <Stack spacing={2}>
                    <Typography variant='heavy' sx={{ 'fontSize': '16px' }}>Total Physicians in Campaign: {Object.values(physicians).length}</Typography>
                    <Typography variant='heavy' sx={{ 'fontSize': '16px' }}>Physicians without Phone Number: {physiciansWithoutPhoneNumber.length}</Typography>
                    <Typography variant='heavy' sx={{ 'fontSize': '16px' }}>Physicians with Texting Disabled: {physiciansWithTextingDisabled.length}</Typography>
                    <Typography variant='heavy' sx={{ 'fontSize': '16px' }}>Physicians with at Least One Review Completed: {isFollowUpText ? physiciansWithReviewsCompleted.length : 'N/A'}</Typography>
                    <Typography variant='heavy' sx={{ 'fontSize': '16px' }}>Recently Texted Physicians: {recentlyTextedPhysicians.length}</Typography>
                    { isKickoffText && 
                        <Typography variant='light' sx={{ 'fontSize': '14px' }}>
                            Note: Kickoff texts are always sent to all users in a campaign (unless they have opted out of texting or already recevied a text in the past 24 hours).
                        </Typography>
                    }
                </Stack>
            </Stack>
            <Stack>
                <Typography variant='heavy' sx={{ 'fontSize': '16px' }}>Physicians Projected to Receive Text Message: {physiciansExpectedToReceiveTextMessage ? physiciansExpectedToReceiveTextMessage.length : 'N/A'}</Typography>
            </Stack>
        </Stack>
    );
}


export default TextingPreviewSummary;
