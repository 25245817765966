import React, { useState, useMemo } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Stack, Typography, TableContainer, Table, TableHead, TableCell } from '@mui/material';
import { theme } from '../../../theme';
import StaffReviewItemEntry from './StaffReviewItemEntry';

function ItemCategory(props) {
    const [selectedItem, setSelectedItem] = useState(null);

    const open = props.selectedCategory === props.categoryName;

    const numberToReview = useMemo(() => {
        return props.review.recommendations[props.categoryName]?.filter((rec) => rec.review[0]?.status === 'open').length ?? 0;
    }, [props.review.recommendations, props.categoryName]);

    const allRows = useMemo(() => {
        var rows = [];

        Object.keys(props.review.items[props.categoryName]).forEach((item) => {
            rows.push(props.review.items[props.categoryName][item]);
        });

        props.review.recommendations[props.categoryName]?.filter((rec) => rec.type === 'addition' && rec.status === 'accepted').forEach((rec) => {
            rows.push(rec.item);
        });

        return rows;
    }, [props.review.items, props.review.recommendations, props.categoryName]);

    function handleClick() {
        if (open) {
            props.setSelectedCategory(null);
        } else {
            props.setSelectedCategory(props.categoryName);
        }
    }

    return (
        <Stack backgroundColor='white' borderRadius='8px' border='1px solid' borderColor={theme.palette.border.main}>
            <Stack direction='row' p={2} alignItems='center' justifyContent='space-between' onClick={handleClick} style={{ cursor: 'pointer' }}>
                <Stack direction='row' spacing={2}>
                    { open ? 
                        <ExpandLess style={{ color: theme.palette.subtext.main }} /> :
                        <ExpandMore style={{ color: theme.palette.subtext.main }} />
                    }
                    <Typography variant='bold' fontSize='14px'>{props.categoryName}</Typography>
                </Stack>
                { numberToReview > 0 &&
                    <Typography 
                        variant='normal' 
                        color={theme.palette.blue.main} 
                        fontSize='14px'
                    >
                        {numberToReview} Request{numberToReview > 1 ? 's' : ''} to Review
                    </Typography>
                }
            </Stack>
            <Collapse in={open} unmountOnExit timeout='auto'>
                <TableContainer style={{
                    borderTop: '1px solid',
                    borderColor: theme.palette.border.main,
                }}>
                    <Table>
                        <TableHead>
                            <TableCell style={{ border: 'none' }} />
                            <TableCell style={{ border: 'none' }}><strong>Item</strong></TableCell>
                            <TableCell style={{ border: 'none' }}><strong>Item ID</strong></TableCell>
                            <TableCell style={{ border: 'none' }}><strong>Requested Optimization</strong></TableCell>
                            <TableCell style={{ textAlign: 'center', border: 'none' }}><strong>Open</strong></TableCell>
                            <TableCell style={{ textAlign: 'center', border: 'none' }}><strong>Hold</strong></TableCell>
                            <TableCell style={{ textAlign: 'center', border: 'none' }}><strong>New Open</strong></TableCell>
                            <TableCell style={{ textAlign: 'center', border: 'none' }}><strong>New Hold</strong></TableCell>
                            <TableCell style={{ textAlign: 'end', border: 'none' }}><strong>Review Status</strong></TableCell>
                        </TableHead>
                        { allRows.map((item, index) => {
                            const rec = props.review.recommendations[props.categoryName]?.find((rec) => rec.item.id === item.id) ?? null;

                            return (
                                <StaffReviewItemEntry
                                    key={item.id}
                                    index={index}
                                    item={item}
                                    recommendation={rec}
                                    review={rec?.review.length > 0 ? rec.review[0] : null}
                                    handleUpdate={props.handleUpdate}
                                    disabled={props.isReadOnly || (rec?.review.length > 0 && rec?.review[0].submitted_at !== null)}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                />
                            );
                        })}
                    </Table>
                </TableContainer>
            </Collapse>
        </Stack>
    );
}

export default ItemCategory;
