import React, { useEffect, useState, useMemo } from 'react';
import SurgeonCostEfficiency from './User Efficiency/SurgeonCostEfficiency';
import PeerLeaderboard from './Leaderboard/PeerLeaderboard';
import useAnalytics from '../../Hooks/useAnalytics';
import TaskList from '../TaskList/TaskList';
import HeadlineBanner from './HeadlineBanner';
import HeadlineHeader from './HeadlineHeader';
import { useGetPhysicianCampaignDraftsQuery, useGetHeadlineDataQuery, useGetCampaignSummariesQuery } from '../../../api/physicianDashboardSlice';
import PersonalComplianceTile from './HeadlineCompliance/PersonalComplianceTile';
import { Stack } from '@mui/system';
import SiteComplianceTile from './HeadlineCompliance/SiteComplianceTile';
import Confidentiality from './Confidentiality/Confidentiality';
import { Backdrop } from '@mui/material';
import LogOnEnter from '../../CustomComponents/LogOnEnter';
import InvalidToken from '../InvalidToken';
import LoadingIndicator from '../../LoadingIndicator';


function Headline(props) {
    const analytics = useAnalytics();
    const [hasAcceptedConfidentiality, setHasAcceptedConfidentiality] = useState(sessionStorage.getItem('hasAcceptedConfidentiality') === 'true');

    useEffect(() => {
        analytics.track('Page Loaded', {
            sector: 'Physician Dashboard',
            name: 'Headline'
        });
    }, []);

    const { 
        data: headlineData = {},
        isLoading: headlineDataLoading,
        isError: headlineDataError
    } = !props.campaign ? useGetHeadlineDataQuery({ physicianId: props.cardOwner.id }) :
        useGetHeadlineDataQuery({ physicianId: props.cardOwner.id, campaignId: props.campaign.id });

    const {
        data: drafts = [],
        isSuccess,
        isLoading: draftsLoading,
    } = useGetPhysicianCampaignDraftsQuery({physicianId: props.cardOwner.id, campaignId: props.campaign?.id});

    const {
        data: campaignSummaries = [],
    } = useGetCampaignSummariesQuery({physicianId: props.cardOwner.id, status: 'active'});

    
    const campaignProgress = campaignSummaries.map(campaign => {
        return {
            'hospital_name': campaign.hospital.name,
            'campaign_id': campaign.campaign_id,
            'campaign_name': campaign.campaign_name,
            'isReviewed': campaign.current_progress === campaign.max_progress,
            'campaign_type': campaign.campaign_type,
        }
    });

    const sortedCampaignProgress = useMemo(() => {
        return campaignProgress.slice().sort(function (a, b) {
            return (a.isReviewed && b.isReviewed || !a.isReviewed && !b.isReviewed) ? 0 :
                a.isReviewed ? -1 : 1;
        });
    }, [campaignProgress]);
    const currentCampaign = !props.campaign ? null : 
        campaignProgress[campaignProgress.findIndex(campaign => campaign.campaign_id == props.campaign.id)];

    const shouldShowComplianceTiles = () => {
        return headlineData.tasks?.total_compliance_swaps > 0;
    }

    const shouldShowEfficiencyTile = () => {
        return headlineData.efficiency !== undefined;
    }

    const shouldShowLeaderboard = () => {
        return headlineData.leaderboard !== undefined || headlineData.compliance_leaderboard !== undefined;
    }

    const shouldShowConfidentiality = useMemo(() => {
        return !hasAcceptedConfidentiality && headlineData.confidentiality_notice === true;
    }, [hasAcceptedConfidentiality, headlineData.confidentiality_notice]);


    const shouldShowHeadlineBanner = () => {
        if (!props.campaign) {
            return false;
        }        return campaignSummaries.length > 1 && currentCampaign.isReviewed === true;
    }
    
    return (
        <LogOnEnter action='viewed_headline' data={{}}>
            <Stack spacing={2} pb={6}>
                <HeadlineHeader cardOwner={props.cardOwner} campaign={props.campaign} showButton={!props.campaign ? false : true}/>
                { headlineDataError && 
                    <InvalidToken resendButton={true} />
                }
                <Stack spacing={2} p={2} sx={{paddingTop: '0px' }}>
                    { shouldShowHeadlineBanner() && 
                        <HeadlineBanner campaignProgress={sortedCampaignProgress} currentCampaign={currentCampaign} />
                    }
                    { shouldShowComplianceTiles() &&
                        <PersonalComplianceTile complianceDebt={headlineData.compliance_debt} complianceCategory={Object.keys(headlineData.overall_compliance)[0]} />
                    }
                    { shouldShowLeaderboard() &&
                        <PeerLeaderboard cardOwner={props.cardOwner} headlineData={headlineData} siteCompliance={headlineData.overall_compliance} />
                    }
                    { shouldShowEfficiencyTile() && 
                        <SurgeonCostEfficiency efficiencyData={headlineData.efficiency} drafts={drafts} draftsLoaded={isSuccess} />
                    }
                    { shouldShowComplianceTiles() && 
                        <SiteComplianceTile siteCompliance={headlineData.overall_compliance} hospital={props.cardOwner.hospital} />
                    }
                    { (headlineDataLoading || draftsLoading) ?
                        <LoadingIndicator /> : 
                        <TaskList cardOwner={props.cardOwner} drafts={drafts} headlineData={headlineData} campaign={props.campaign} />
                    }
                </Stack>
            </Stack>
            <Backdrop open={shouldShowConfidentiality} sx={{ zIndex: 1200 }}>
                <Confidentiality setHasAcceptedConfidentiality={setHasAcceptedConfidentiality} physicianId={props.cardOwner.id} />
            </Backdrop>
        </LogOnEnter>
    );
}

export default Headline;
