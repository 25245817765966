import { Stack, Typography } from '@mui/material';
import React from 'react';
import ActiveCardEntry from '../../CardList/ActiveCardEntry';
import SubmittedCardEntry from '../../CardList/SubmittedCardEntry';

function HeadlineCardList(props) {
    return (
        <Stack spacing={2}>
            { props.drafts.length === 0 &&
                <Stack spacing={2} alignItems='center' justifyContent='center' height='100%'>
                    { props.cardToggle === 'unreviewed' ?
                        <Typography variant='normal'>No cards left to review. Great work!</Typography> :
                        <Typography variant='normal'>No cards have been submitted yet.</Typography>
                    }
                </Stack>
            }
            { props.drafts.map((draft) => (
                (draft.status === 'submitted' || draft.status === 'applied') ? 
                    <SubmittedCardEntry key={draft.id} physician={props.currentUser} draft={draft} /> :
                    <ActiveCardEntry key={draft.id} physician={props.currentUser} draft={draft} campaignHasOptimizations={props.campaignHasOptimizations} />
            ))}
        </Stack>
    );
}

export default HeadlineCardList;
