import React from 'react';
import SortableTableHeader from '../../../CustomComponents/SortableTableHeader';

function PhysicianHeader(props) {
    const headers = [
        {
            id: 'last_name',
            label: 'Physician',
            tooltip: null,
            ratio: 14,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'progress',
            label: 'Physician Review Progress',
            tooltip: null,
            ratio: 35,
            justify: 'start',
            sortable: false,
        },
        {
            id: 'recommendation_summary.original_opportunity',
            label: 'Opportunity',
            tooltip: null,
            ratio: 12,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'recommendation_summary.accepted_value',
            label: 'Approved',
            tooltip: null,
            ratio: 12,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'recommendation_summary.rejected_value',
            label: 'Denied',
            tooltip: null,
            ratio: 12,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'recommendation_summary.open_value',
            label: 'Unreviewed',
            tooltip: null,
            ratio: 15,
            justify: 'start',
            sortable: true,
        },
    ];

    return (
        <SortableTableHeader headers={headers} {...props} />
    );
}

export default PhysicianHeader;
