import { physicianDashboardSlice } from './physicianDashboardSlice';


export const itemRecommendationSlice = physicianDashboardSlice.injectEndpoints({
    endpoints: builder => ({
        acceptRecommendation: builder.mutation({
            query: ({ draft, recommendation }) => ({
                url: `/drafts/${draft.id}/recommendations/${recommendation.id}`,
                method: 'PATCH',
                body: {
                    status: 'accepted'
                }
            }),
            async onQueryStarted({ draft, recommendation }, { dispatch, queryFulfilled }) {
                const result = dispatch(
                    physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                        let existingRecommendation = draft.recommendations[recommendation.item.category].find(r => r.id === recommendation.id);
                        if (existingRecommendation) {
                            existingRecommendation.status = 'accepted';
                        }
                    })
                )
                try {
                    const { data: updatedDraft } = await queryFulfilled;
                    dispatch(
                        physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                            Object.assign(draft, updatedDraft);
                        })
                    )
                } catch {
                    result.undo();
                }
            },
            invalidatesTags: ['CampaignSummary'],
        }),
        acceptRecommendationWithChanges: builder.mutation({
            query: ({ draft, recommendation, status, customValue, customOpen, customHold, newItem }) => ({
                url: `/drafts/${draft.id}/recommendations/${recommendation.id}`,
                method: 'PATCH',
                body: {
                    status: status,
                    custom_value: customValue,
                    custom_open: customOpen,
                    custom_hold: customHold,
                    recommended_item_id: newItem?.id
                }
            }),
            async onQueryStarted({ draft, recommendation, status }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedDraft } = await queryFulfilled;
                    setTimeout(() => {
                        dispatch(
                            physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                                Object.assign(draft, updatedDraft);
                            })
                        )
                    }, 250);
                } catch {
                    result.undo();
                }
            }
        }),
        declineRecommendation: builder.mutation({
            query: ({ draft, recommendation }) => ({
                url: `/drafts/${draft.id}/recommendations/${recommendation.id}`,
                method: 'PATCH',
                body: {
                    status: 'rejected'
                }
            }),
            async onQueryStarted({ draft, recommendation }, { dispatch, queryFulfilled }) {
                const result = dispatch(
                    physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                        let existingRecommendation = draft.recommendations[recommendation.item.category].find(r => r.id === recommendation.id);
                        if (existingRecommendation) {
                            existingRecommendation.status = 'rejected';
                        }
                    })
                )
                try {
                    const { data: updatedDraft } = await queryFulfilled;
                    dispatch(
                        physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                            Object.assign(draft, updatedDraft);
                        })
                    )
                } catch {
                    result.undo();
                }
            },
            invalidatesTags: ['CampaignSummary'],
        }),
        undoRecommendation: builder.mutation({
            query: ({ draft, recommendation }) => ({
                url: `/drafts/${draft.id}/recommendations/${recommendation.id}`,
                method: 'PATCH',
                body: {
                    status: 'open',
                    custom_value: null,
                    custom_open: null,
                    custom_hold: null,
                    recommended_item_id: null
                }
            }),
            async onQueryStarted({ draft, recommendation }, { dispatch, queryFulfilled }) {
                const result = dispatch(
                    physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                        let existingRecommendation = draft.recommendations[recommendation.item.category].find(r => r.id === recommendation.id);
                        if (existingRecommendation) {
                            existingRecommendation.status = 'open';
                        }
                    })
                )
                try {
                    const { data: updatedDraft } = await queryFulfilled;
                    dispatch(
                        physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                            Object.assign(draft, updatedDraft);
                        })
                    )
                } catch {
                    result.undo();
                }
            },
            invalidatesTags: ['CampaignSummary'],
        }),
        createCustomRecommendation: builder.mutation({
            query: ({ draft, item, status, customValue, customOpen, customHold, newItem }) => ({
                url: `/drafts/${draft.id}/recommendations`,
                method: 'POST',
                body: {
                    item_id: item.id,
                    recommended_item_id: newItem?.id,
                    status: status,
                    type: 'custom_user_edit',
                    recommendation_value: 0,
                    custom_value: customValue,
                    custom_open: customOpen,
                    custom_hold: customHold,
                    context: [],
                    rejection_context: []
                }
            }),
            async onQueryStarted({ draft }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedDraft } = await queryFulfilled;
                    dispatch(
                        physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                            Object.assign(draft, updatedDraft);
                        })
                    )
                } catch {}
            }
        }),
        undoCustomRecommendation: builder.mutation({
            query: ({ draft, recommendation }) => ({
                url: `/drafts/${draft.id}/recommendations/${recommendation.id}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ draft }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedDraft } = await queryFulfilled;
                    dispatch(
                        physicianDashboardSlice.util.updateQueryData('getDraft', draft.id.toString(), draft => {
                            Object.assign(draft, updatedDraft);
                        })
                    )
                } catch {}
            }
        }),
    })
});

export const { 
    useAcceptRecommendationMutation,
    useAcceptRecommendationWithChangesMutation,
    useDeclineRecommendationMutation,
    useUndoRecommendationMutation,
    useCreateCustomRecommendationMutation,
    useUndoCustomRecommendationMutation,
} = itemRecommendationSlice;

