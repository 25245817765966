import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import UserEfficiencyBar from './UserEfficiencyBar';
import {NavigationRounded} from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';
import LogOnEnter from '../../../CustomComponents/LogOnEnter';

function SurgeonCostEfficiency(props) {
    const statisticCards = useMemo(() => { 
        return props.drafts.slice()
            .sort((a, b) => b.cost_reduction_opportunity - a.cost_reduction_opportunity)
            .sort((a, b) => b.impact_score - a.impact_score)
            .slice(0, 3)
            .filter((draft) => draft.status !== 'submitted');
    }, [props.drafts]);

    const potentialSavings = useMemo(() => {
        if (statisticCards.length === 0) {
            return 0;
        }
        var sum = 0;
        statisticCards.forEach((draft) => {
            sum += draft.cost_reduction_opportunity;
        });
        return sum;
    }, [statisticCards]);

    return (
        <LogOnEnter action='viewed_surgeon_efficiency' data={{ efficiency: props.efficiencyData?.current, peer_average: props.efficiencyData?.average }}>
            <Stack pb={2} spacing={1}>
                <Box sx={{ backgroundColor: 'white', borderRadius: '4px' }}>
                    <Stack p={2} spacing={1}>
                        <Box>
                            <Typography variant='heavy' sx={{ fontSize: '16px' }}>
                                My Overall Cost Efficiency
                            </Typography>
                        </Box>
                        <UserEfficiencyBar efficiency={props.efficiencyData?.current ?? 0} peerAverage={props.efficiencyData.average} />
                    </Stack>
                    { props.draftsLoaded && 
                        <Stack px={2} pb={2}>
                            { statisticCards.length > 0 ? 
                                <Stack spacing={1}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <NavigationRounded sx={{ transform: 'rotate(90deg)', fontSize: '12px' }} />
                                        { potentialSavings > 100000 ? 
                                            <Typography variant='normal' sx={{ fontSize: '12px', lineHeight: '1.25' }}>
                                                Your least efficient { statisticCards.length === 1 ? 'remaining card has' : statisticCards.length + ' cards have'} <NumericFormat value={potentialSavings / 100} thousandSeparator={true} prefix={'$'} displayType={'text'} decimalScale={0} /> of cost reduction opportunity
                                            </Typography> :
                                            <Typography variant='normal' sx={{ fontSize: '12px', lineHeight: '1.25' }}>
                                                Limited specific optimizations have been identified for your cards. However, please review your frequently used cards, using your expertise to make any improvements.
                                            </Typography>
                                        }
                                    </Stack>
                                    { potentialSavings > 100000 &&
                                        <Stack direction='row' spacing={1} alignItems='center'>
                                            <NavigationRounded sx={{ transform: 'rotate(90deg)', fontSize: '12px' }} />
                                            <Typography variant='normal' sx={{ fontSize: '12px', lineHeight: '1.25' }}>
                                                Reviewing these items can boost your efficiency by +{Math.round(props.efficiencyData?.potential_gain * 100)}%
                                            </Typography>
                                        </Stack>
                                    }
                                </Stack>
                                :
                                <Stack spacing={1}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <NavigationRounded sx={{ transform: 'rotate(90deg)', fontSize: '12px' }} />
                                        <Typography variant='normal' sx={{ fontSize: '12px', lineHeight: '1.25' }}>
                                            {'Congrats! You\'ve reviewed your highest priority cards!'}
                                        </Typography>
                                    </Stack>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <NavigationRounded sx={{ transform: 'rotate(90deg)', fontSize: '12px' }} />
                                        <Typography variant='normal' sx={{ fontSize: '12px', lineHeight: '1.25' }}>
                                            {'Tap \'View All Cards\' to see more cards and keep reviewing.'}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            }   
                        </Stack>
                    }
                </Box>
            </Stack>
        </LogOnEnter>
    );
}

export default SurgeonCostEfficiency;
