import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetHospitalQuery, useGetIngestionTokenQuery } from '../../../api/apiSlice';
import IngestionBox from './IngestionBox';
import PastUploads from './PastUploads';
import LoadingIndicator from '../../LoadingIndicator';

function Ingestion() {
    const params = useParams();
    const {
        data: hospital,
        isLoading: hospitalLoading
    } = useGetHospitalQuery(params.hospitalId);
    const {
        data: ingestionToken
    } = useGetIngestionTokenQuery();

    return (
        hospitalLoading && <LoadingIndicator /> ||
        !hospitalLoading &&
        <Stack ml={4} spacing={3} pb={10} sx={{ maxHeight: '100vh', overflow: 'auto' }}>
            <Stack spacing={3}>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Ingestion Page</Typography>
                <Typography variant='normal' sx={{ fontSize: '16px' }}>Uploading for: {hospital.name}</Typography>
                <Stack direction='row' spacing={4}>
                    <IngestionBox title='Add Physicians' uploadUrl='physicianUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Preference Cards' uploadUrl='cardUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Inventory Items' uploadUrl='itemUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Procedures' uploadUrl='procedureUpload' token={ingestionToken?.token} hospital={hospital} />
                </Stack>
                <Stack direction='row' spacing={4}>
                    <IngestionBox title='Add Card Items' uploadUrl='cardItemUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Card Items (Beta)' uploadUrl='v2/cardItemUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Card Procedures' uploadUrl='cardProcedureUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Card Comments' uploadUrl='cardCommentUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Auxiliary Items' uploadUrl='auxiliaryItemUpload' token={ingestionToken?.token} hospital={hospital} />
                </Stack>
                <Stack direction='row' spacing={4}>
                    <IngestionBox title='Add Item Mappings' uploadUrl='itemMappingUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Compliance Carve Outs' uploadUrl='carveOutUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Compliance Exceptions' uploadUrl='complianceExceptionUpload' token={ingestionToken?.token} hospital={hospital} />
                    <IngestionBox title='Add Case Logs' uploadUrl='caseItemLogUpload' token={ingestionToken?.token} hospital={hospital} />
                </Stack>
            </Stack>
            <Stack spacing={4} maxWidth='95%'>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Current and Recent Uploads</Typography>
                <PastUploads hospitalId={hospital.id} token={ingestionToken?.token}/>
            </Stack>
        </Stack>
    );
}

export default Ingestion;