import { Stack } from '@mui/material';
import React from 'react';
import ItemDetail from './ItemDetail';

function ItemChipSection(props) {
    function calculateItemSpend() {
        var openQuantity = props.recommendation.custom_open ?? (props.recommendation.open_quantity ?? 0);
        var holdQuantity = props.recommendation.custom_hold ?? (props.recommendation.hold_quantity ?? 0);

        return (props.item.cost * (openQuantity + (holdQuantity * 0.3)) * (props.yearlyUsage ?? 1)) / 100;
    }

    return (
        <Stack direction='row' spacing={1}>
            { Object.keys(props.recommendation.context).map((context, index) => (
                <ItemDetail 
                    key={index} 
                    type={context} 
                    data={props.recommendation.context[context]}
                />
            ))}
            { props.recommendation.type !== 'addition' && 
                <ItemDetail key='cost' type='cost' data={calculateItemSpend()} />
            }
        </Stack>
    )
}

export default ItemChipSection;
