import React, { useState } from 'react';
import { Stack, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import HeadlineCardList from '../Headline/Headline Cards/HeadlineCardList';
import TaskHeader from './TaskHeader';
import { useMemo } from 'react';
import { useLogUserActionMutation } from '../../../api/physicianDashboardSlice';

function ReviewCardsTask(props) {
    const [isShowingAllCards, setIsShowingAllCards] = useState(!props.campaignHasOptimizations);
    const [cardToggle, setCardToggle] = useState('unreviewed');
    const [logPhysicianAction] = useLogUserActionMutation();

    const submittedDrafts = props.drafts.filter((draft) => (draft.status === 'submitted' || draft.status === 'applied')).slice().sort((a, b) =>
        (b.card.yearly_stats?.usage_count ?? 1) - (a.card.yearly_stats?.usage_count ?? 1)
    ).sort((a, b) => b.cost_reduction_opportunity - a.cost_reduction_opportunity).sort((a, b) => b.impact_score - a.impact_score);
    const unsubmittedDrafts = props.drafts.filter((draft) => draft.status === 'open' || draft.status === 'review').slice().sort((a, b) =>
        (b.card.yearly_stats?.usage_count ?? 1) - (a.card.yearly_stats?.usage_count ?? 1)
    ).sort((a, b) => b.cost_reduction_opportunity - a.cost_reduction_opportunity).sort((a, b) => b.impact_score - a.impact_score);

    const visibleDrafts = useMemo(() => {
        if (cardToggle === 'unreviewed' && !isShowingAllCards) {
            return unsubmittedDrafts.slice(0, 3);
        } else if (cardToggle === 'unreviewed' && isShowingAllCards) {
            return unsubmittedDrafts;
        } else {
            return submittedDrafts;
        }
    }, [isShowingAllCards, cardToggle, submittedDrafts, unsubmittedDrafts]);

    function handleButtonClick() {
        setIsShowingAllCards(!isShowingAllCards);
        logPhysicianAction({
            action: isShowingAllCards ? 'viewed_top_cards' : 'viewed_all_cards',
            data: {}
        });
    }

    function handleToggleCardType(value) {
        if (value !== null) {
            setCardToggle(value);
            logPhysicianAction({
                action: 'toggled_card_filter',
                data: {
                    card_filter: value,
                }
            });
        }
    }

    return (
        <Stack spacing={2}>
            <Stack spacing={1} direction='row' justifyContent='space-between' alignItems='center'>
                <TaskHeader title={'Review Your Cards'} taskCount={unsubmittedDrafts.length} shouldShowCount={!isShowingAllCards} />
                <ToggleButtonGroup value={cardToggle} exclusive onChange={(e, value) => handleToggleCardType(value)} color='primary' size='small'>
                    <ToggleButton value='unreviewed'>
                        Unreviewed
                    </ToggleButton>
                    <ToggleButton value='reviewed'>
                        Reviewed
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <HeadlineCardList currentUser={props.cardOwner} drafts={visibleDrafts} campaignHasOptimizations={props.campaignHasOptimizations} cardToggle={cardToggle} />
            { !isShowingAllCards && cardToggle === 'unreviewed' && unsubmittedDrafts.length > 3 &&
                <Button onClick={handleButtonClick} variant='contained' sx={{ alignSelf: 'center' }}>
                    {`Load All ${unsubmittedDrafts.length} Cards`}
                </Button>
            }
        </Stack>
    );
}

export default ReviewCardsTask;