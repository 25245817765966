import React from 'react';
import { Drawer, Stack, Typography, IconButton, Divider } from '@mui/material';
import { useGetComparableItemsQuery } from '../../../../api/physicianDashboardSlice';
import { Close } from '@mui/icons-material';
import ComparableItemEntry from './ComparableItemEntry';
import { theme } from '../../../../theme';

function ReplaceItem(props) {
    const { data: comparableItems = [] } = useGetComparableItemsQuery({
        hospitalId: props.draft.card.hospital.id,
        cardId: props.draft.card.id,
        category: encodeURIComponent(props.item.category),
        procedureId: props.draft.card.primary_procedure_id,
    });

    const currentItem = comparableItems.filter(item => item.id === props.item.id);

    const comparableItemsNotOnCard = comparableItems.filter(item => { 
        return !props.draft.items[props.item.category]?.some(draftItem => draftItem.id === item.id);
    });

    const comparableItemsOnCard = comparableItems.filter(item => {
        return item.id !== props.item.id && props.draft.items[props.item.category]?.some(draftItem => draftItem.id === item.id);
    }); // Not including the original item

    const draftItems = props.draft.items[props.item.category] ?? [];

    const sortedComparableItemsOnCard = comparableItemsOnCard.slice().sort((a, b) => {
        return b.prevalence - a.prevalence;
    });

    const sortedComparableItemsNotOnCard = comparableItemsNotOnCard.slice().sort((a, b) => {
        return b.prevalence - a.prevalence;
    });

    function handleClose() {
        props.setOpen(false);
    }

    return (
        <Drawer open={props.open} onClose={handleClose} anchor='bottom'>
            <Stack position='sticky' backgroundColor='#00000000' sx={{ top: 0, zIndex: 1 , boxShadow: 1, }}>
                <Stack spacing={1} p={2}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='heavy' fontSize='20px'>Replace Item?</Typography>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Typography variant='normal' fontSize='14px'>Choose from other commonly used '{props.item.category}' items</Typography>
                </Stack>
                <Divider orientation='horizontal' />
            </Stack>
            <Stack height='100%' backgroundColor='#0000000F' p={2} spacing={2} sx={{ overflowY: 'scroll' }}>
                {/* Current Item */}
                <Divider orientation='horizontal'><Typography variant='normal' sx={{ color: `${theme.palette.subtext.main}`}}>Current Item</Typography></Divider>
                <ComparableItemEntry originalItem={props.item} item={currentItem[0]} draftItems={draftItems} swapItem={props.swapItem} />
                {/* Not on card Items */}
                { sortedComparableItemsNotOnCard.length > 0 &&
                    <Divider orientation='horizontal'><Typography variant='normal' sx={{ color: `${theme.palette.subtext.main}`}}>Items not on this card</Typography></Divider>
                }
                { sortedComparableItemsNotOnCard.map((item, index) => (
                    <ComparableItemEntry key={index} originalItem={props.item} item={item} draftItems={draftItems} swapItem={props.swapItem} />
                ))}
                {/* On card Items */}
                { sortedComparableItemsOnCard.length > 0 &&
                    <Divider orientation='horizontal'><Typography variant='normal' sx={{ color: `${theme.palette.subtext.main}`}}>Items already on this card</Typography></Divider>
                }
                { sortedComparableItemsOnCard.map((item, index) => (
                    <ComparableItemEntry key={index} originalItem={props.item} item={item} draftItems={draftItems} swapItem={props.swapItem} />
                ))}
            </Stack>
        </Drawer>
    )
}

export default ReplaceItem;
