import React, { useMemo } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import ItemDetail from '../ItemDetail';
import { NumericFormat } from 'react-number-format';
import ItemDetails from '../../ItemSwaps/ItemDetails';
import { useState } from 'react';

function ComparableItemEntry(props) {
    const [isDetailOpen, setIsDetailOpen] = useState(false);

    const isOnCard = useMemo(() => {
        return props.draftItems.some(item => item.id === props.item.id);
    }, [props.item, props.draftItems]);

    const costDifference = useMemo(() => {
        return Math.round((props.originalItem.cost - props.item.cost) / props.originalItem.cost * 100);
    }, [props.item, props.originalItem]);

    const handleSwap = () => {
        props.swapItem(props.item);
    }

    function toggleIsDetailOpen() {
        setIsDetailOpen(!isDetailOpen);
    }

    return ( 
        <Stack backgroundColor='white' borderRadius='4px' p={2}>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' spacing={2}>
                <Stack spacing={1} maxWidth={{ sm: '65%' }}>
                    <Stack>
                        <Typography variant='normal' fontSize='14px'>{props.item.name}</Typography>
                        <Typography variant='light' fontSize='12px'>{props.item.vendor.name} - {props.item.part_number} | <NumericFormat value={props.item.cost / 100}
                            thousandSeparator={true}
                            displayType={'text'}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true} />
                        </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1}>
                        { props.item.peer_card_count && props.item.prevalence <= 0.33 &&
                            <ItemDetail type='low_prevalence' data={props.item.prevalence} />
                        }
                        { props.item.peer_card_count && props.item.prevalence > 0.33 && props.item.prevalence <= 0.66 &&
                            <ItemDetail type='medium_prevalence' data={props.item.prevalence} />
                        }
                        { props.item.peer_card_count && props.item.prevalence > 0.66 &&
                            <ItemDetail type='high_prevalence' data={props.item.prevalence} />
                        }
                        { props.item.physician_card_count &&
                            <ItemDetail type='physician_card_count' data={props.item.physician_card_count} />
                        }
                        { costDifference > 0 &&
                            <ItemDetail type='cheaper' data={costDifference} />
                        }
                        { costDifference < 0 &&
                            <ItemDetail type='more_expensive' data={Math.abs(costDifference)} />
                        }
                        { costDifference == 0 &&
                            <ItemDetail type='even_cost' />
                        }
                    </Stack>
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent={{ xs: 'space-between', sm: 'center'}} maxWidth={{ sm: '30%' }} spacing={2}>
                    <Button variant='outlined' onClick={toggleIsDetailOpen} style={{ lineHeight: { sm: '1.4' } }}>Item Details</Button>
                    { isOnCard ? 
                        <Button variant='outlined' disabled style={{ lineHeight: { sm: '1.4' } }} >Already on Card</Button> :
                        <Button variant='blueContained' onClick={handleSwap} style={{ boxShadow: 0, lineHeight: { sm: '1.4' } }}>Swap Item</Button>
                    }
                </Stack>
            </Stack>
            <ItemDetails item={props.item} isOpen={isDetailOpen} startingValue={0} handleClose={toggleIsDetailOpen}/>
        </Stack>
    );
}

export default ComparableItemEntry;