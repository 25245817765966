import { ArrowForwardRounded, ContentCopyRounded, ExpandLess, ExpandMore } from '@mui/icons-material';
import { TableRow, TableCell, Collapse, Stack, Typography, IconButton, ClickAwayListener, Tooltip } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { theme } from '../../../theme';
import StaffReviewItemDetails from './StaffReviewItemDetails';
import ActionDropdown from './ActionDropdown';
import OverdueChip from './OverdueChip';
import ReviewerFeedback from './ReviewerFeedback';
import { parseISO, format } from 'date-fns';

function StaffReviewItemEntry(props) {
    const isExpanded = props.selectedItem === props.item.id;
    const [copied, setCopied] = useState(false);

    const feedbackOpen = useMemo(() => {
        return props.review?.status === 'rejected' || isExpanded;
    }, [props.review, isExpanded]);

    function handleItemClick() {
        if (isExpanded) {
            props.setSelectedItem(null);
        } else {
            props.setSelectedItem(props.item.id);
        }
    }

    function isCustomEdit(rec) {
        return rec.type === 'custom_user_edit' || rec.status.split('_')[0] === 'custom';
    }

    function isRemoval(rec) {
        return rec.status === 'custom_removal' || (rec.type === 'removal' && !isCustomEdit(rec));
    }

    function isSwap(rec) {
        return rec?.type === 'swap' || rec?.type === 'compliance' || (rec?.type === 'custom_user_edit' && rec?.status === 'custom_swap');
    }

    const recTypeText = {
        addition: 'Add to Card',
        removal: 'Remove from Card',
        update: 'Quantity Change',
        custom_removal: 'Remove from Card',
        custom_update: 'Quantity Change',
        custom_swap: <Stack direction='row' alignItems='center'>Swap to New Item <ArrowForwardRounded size='small' style={{ paddingLeft: '8px', color: theme.palette.subtext.main }}/></Stack>,
        swap: <Stack direction='row' alignItems='center'>Swap to New Item <ArrowForwardRounded size='small' style={{ paddingLeft: '8px', color: theme.palette.subtext.main }}/></Stack>,
        compliance: <Stack direction='row' alignItems='center'>Swap to New Item <ArrowForwardRounded size='small' style={{ paddingLeft: '8px', color: theme.palette.subtext.main }}/></Stack>,
    };

    function getRecTypeText(rec) {
        if (isCustomEdit(rec)) {
            return recTypeText[rec.status];
        }
        return recTypeText[rec.type];
    }

    function handleCopy(item) {
        navigator.clipboard.writeText(item.external_id);
        setCopied(true);
    }

    return (
        <>
            <TableRow style={{ borderTop: '1px solid', borderColor: theme.palette.border.main }} >
                <TableCell style={{ cursor: 'pointer' }} onClick={handleItemClick}>
                    { isExpanded ? 
                        <ExpandLess style={{ color: theme.palette.subtext.main, cursor: 'pointer' }} /> :
                        <ExpandMore style={{ color: theme.palette.subtext.main, cursor: 'pointer' }} />
                    }
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} onClick={handleItemClick}>
                    <Stack>
                        <Typography variant='normal' fontSize='14px'>{props.item.name}</Typography>
                        <Typography variant='normal' fontSize='12px'>{props.item.vendor.name} | {props.item.part_number}</Typography>
                    </Stack>
                </TableCell>
                <TableCell>
                    <Stack>
                        <Stack direction='row' spacing={0.5} alignItems='center'>
                            <Typography variant='normal' fontSize='14px'>{props.item.external_id}</Typography>
                            <ClickAwayListener onClickAway={() => setCopied(false)}>
                                <Tooltip open={copied} title='Copied!' placement='top'>
                                    <IconButton size='small' onClick={() => handleCopy(props.item)}>
                                        <ContentCopyRounded fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell>
                    {props.recommendation ? getRecTypeText(props.recommendation) : 'None'}
                </TableCell>
                { !isSwap(props.recommendation) &&
                    <>
                        <TableCell style={{ textAlign: 'center' }}>
                            {props.item?.open_quantity ?? '-'}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            {props.item?.hold_quantity ?? '-'}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            {isRemoval(props.recommendation) ? '-' : (props.recommendation?.custom_open ?? props.recommendation?.open_quantity)}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                            {isRemoval(props.recommendation) ? '-' : (props.recommendation?.custom_hold ?? props.recommendation?.hold_quantity)}
                        </TableCell>
                    </>
                }
                { isSwap(props.recommendation) &&
                    <>
                        <TableCell style={{ textAlign: 'left' }} colSpan={2}>
                            <Typography variant='normal' fontSize='14px'><strong>New Item: </strong>{props.recommendation?.recommended_item.name}</Typography>
                        </TableCell>
                        <TableCell style={{ textAlign: 'left' }} colSpan={2}>
                            <Typography variant='normal' fontSize='14px'><strong>New ID: </strong>{props.recommendation?.recommended_item.external_id}</Typography>
                            <ClickAwayListener onClickAway={() => setCopied(false)}>
                                <Tooltip open={copied} title='Copied!' placement='top'>
                                    <IconButton size='small' onClick={() => handleCopy(props.recommendation?.recommended_item)} style={{ padding: 0 }}>
                                        <ContentCopyRounded fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                        </TableCell>
                    </>
                }
                <TableCell style={{ padding: 0, textAlign: 'end' }}>
                    { props.review && 
                        <Stack direction='row' alignItems='center' justifyContent='flex-end'>
                            { props.review.is_overdue && props.review.status === 'open' &&
                                <OverdueChip />
                            }
                            <ActionDropdown 
                                review={props.review} 
                                recommendation={props.recommendation} 
                                disabled={props.disabled} 
                                handleUpdate={props.handleUpdate} 
                            />
                        </Stack>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={feedbackOpen} timeout="auto" unmountOnExit>
                        <Stack direction='row' p={2} spacing={2} alignItems='center' justifyContent='flex-end' backgroundColor={theme.palette.background.darkGray}>
                            { props.review && props.review.status === 'rejected' && 
                                <ReviewerFeedback 
                                    review={props.review} 
                                    recommendation={props.recommendation} 
                                    handleUpdate={props.handleUpdate} 
                                    disabled={props.disabled}
                                />
                            }
                            { props.review && props.review.reviewed_at !== null && 
                                <Typography fontSize='12px' minWidth='fit-content'><strong>Reviewed by: </strong> {props.review.editor?.first_name} {props.review.editor?.last_name}, {format(parseISO(props.review.reviewed_at), 'M/d/yy')} </Typography>
                            }
                        </Stack>
                    </Collapse>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <StaffReviewItemDetails item={props.item} recommendation={props.recommendation} review={props.review} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default StaffReviewItemEntry;

